import { Grid, Button, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useCallback } from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="無料でレンタル！ミスプラチナの試着用サンプルリングご利用方法。｜安い、安心、高品質のミスプラチナ"
        description="ミスプラチナはご自宅で試着できるブライダルリングのサンプルを無料レンタルできます。"
        page="rental"
    />
)

const RetalPage: React.FC = () => {
    const muiTheme = useTheme()
    const goToRental = useCallback(() => {
        window.gtag !== undefined && window.gtag('event', 'go_to_rental')
    }, [])
    return (
        <Layout breadcrumbs={[{ name: '試着用サンプルリング' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <H1>
                        <Txt size="l">安心・安全 自宅で試着</Txt>
                    </H1>
                    <section>
                        <H2>
                            <Txt>試着用リング全国へ無料配送中！</Txt>
                        </H2>
                        <StaticImage
                            src="../images/rental/rental_01.jpg"
                            alt=""
                        />
                        <Desc>
                            <Txt size="s">
                                通販だと心配、でも遠くて来店が難しい。忙しくて二人の時間が合わない。店舗に行くのは緊張するしめんどう、、、そんなお客様には気軽に自宅で試着ができるサンプルリングの無料レンタルがおすすめ。
                            </Txt>
                        </Desc>
                        <Table>
                            <Row>
                                <H>
                                    <Txt size="s" bold>
                                        ご利用料金
                                    </Txt>
                                </H>
                                <D>
                                    <Txt size="s">0円</Txt>
                                </D>
                            </Row>
                            <Row>
                                <H>
                                    <Txt size="s" bold>
                                        お貸し出し本数
                                    </Txt>
                                </H>
                                <D>
                                    <Txt size="s">1〜10ペア</Txt>
                                </D>
                            </Row>
                            <Row>
                                <H>
                                    <Txt size="s" bold>
                                        お貸し出し期間
                                    </Txt>
                                </H>
                                <D>
                                    <Txt size="s">1週間</Txt>
                                </D>
                            </Row>
                        </Table>
                        <TableDesc>
                            <SecondaryTxt size="s" theme={muiTheme}>
                                ※サイズ測定用ゲージも同封させて頂きます。
                            </SecondaryTxt>
                            <SecondaryTxt size="s" theme={muiTheme}>
                                ※11ペア以上お貸し出し希望の場合はお気軽にご相談くださいませ。
                            </SecondaryTxt>
                        </TableDesc>
                    </section>
                    <section>
                        <H2>
                            <Txt>試着用リングを全国無料配送</Txt>
                        </H2>
                        <MapImageWrapper>
                            <StaticImage
                                src="../images/rental/rental_02.jpg"
                                alt="試着用リング全国無料発送します"
                            />
                        </MapImageWrapper>
                    </section>
                    <section>
                        <H2>
                            <Txt>レンタルご利用の流れ</Txt>
                        </H2>
                        <StepWrapper>
                            <LeftWrapper>
                                <StepTitleWrapper>
                                    <NumberWrapper>
                                        <Txt>1</Txt>
                                    </NumberWrapper>
                                    <H3>
                                        <StepTitle>
                                            試着するリングをお選びください
                                        </StepTitle>
                                    </H3>
                                </StepTitleWrapper>
                                <FeatureImageWrapper>
                                    <StaticImage
                                        src="../images/rental/rental_step_01.jpg"
                                        alt=""
                                    />
                                </FeatureImageWrapper>
                            </LeftWrapper>
                            <StepDescription size="s">
                                試着用リングは１～10ペアまで、単品あるいは組合せでもご利用頂けます。
                            </StepDescription>
                            <StepDescription size="s">
                                サイズ測定用ゲージも同封致します。
                            </StepDescription>
                        </StepWrapper>
                        <StepWrapper>
                            <RightWrapper>
                                <StepTitleWrapper>
                                    <NumberWrapper>
                                        <Txt>2</Txt>
                                    </NumberWrapper>
                                    <H3>
                                        <StepTitle>
                                            ご希望の商品番号をメール送信
                                        </StepTitle>
                                    </H3>
                                </StepTitleWrapper>
                                <FeatureImageWrapper>
                                    <StaticImage
                                        src="../images/rental/rental_step_02.jpg"
                                        alt=""
                                    />
                                </FeatureImageWrapper>
                            </RightWrapper>
                            <StepDescription size="s">
                                試着希望の商品型番を以下の無料サンプル発注メールフォームからお申込み下さいませ。
                            </StepDescription>
                        </StepWrapper>
                        <StepWrapper>
                            <LeftWrapper>
                                <StepTitleWrapper>
                                    <NumberWrapper>
                                        <Txt>3</Txt>
                                    </NumberWrapper>
                                    <H3>
                                        <StepTitle>試着用リング到着</StepTitle>
                                    </H3>
                                </StepTitleWrapper>
                                <FeatureImageWrapper>
                                    <StaticImage
                                        src="../images/rental/rental_step_03.jpg"
                                        alt=""
                                    />
                                </FeatureImageWrapper>
                            </LeftWrapper>
                            <StepDescription size="s">
                                無料サンプルご注文メール完了日から即日～３営業日でご自宅に試着用リングを発送致します。
                            </StepDescription>
                            <StepDescription size="s">
                                ※試着リング在庫状況により発送が遅れる場合がございます。遅れる場合はご注文時にご連絡させて頂きます。
                            </StepDescription>
                        </StepWrapper>
                        <StepWrapper>
                            <RightWrapper>
                                <StepTitleWrapper>
                                    <NumberWrapper>
                                        <Txt>4</Txt>
                                    </NumberWrapper>
                                    <H3>
                                        <StepTitle>
                                            ご自宅でゆっくり試着
                                        </StepTitle>
                                    </H3>
                                </StepTitleWrapper>
                                <FeatureImageWrapper>
                                    <StaticImage
                                        src="../images/rental/rental_step_04.jpg"
                                        alt=""
                                    />
                                </FeatureImageWrapper>
                            </RightWrapper>
                            <StepDescription size="s">
                                試着用リングはシルバー製のため実際の商品、プラチナ製と比べ軽い材質ですが、形状は全く同じですのでデザインや雰囲気をご確認頂けます。
                            </StepDescription>
                            <StepDescription size="s">
                                ※女性用サイズは10号または13号、男性用は15号または18号のみになります。
                            </StepDescription>
                        </StepWrapper>
                        <StepWrapper>
                            <LeftWrapper>
                                <StepTitleWrapper>
                                    <NumberWrapper>
                                        <Txt>5</Txt>
                                    </NumberWrapper>
                                    <H3>
                                        <StepTitle>
                                            リングの返却、ご注文も簡単
                                        </StepTitle>
                                    </H3>
                                </StepTitleWrapper>
                                <FeatureImageWrapper>
                                    <StaticImage
                                        src="../images/rental/rental_step_05.jpg"
                                        alt=""
                                    />
                                </FeatureImageWrapper>
                            </LeftWrapper>
                            <StepDescription size="s">
                                試着用リングは同封の返送用封筒に入れ1週間以内にお近くの郵便ポストへ投函ください。
                            </StepDescription>
                        </StepWrapper>
                        <StepWrapper>
                            <RightWrapper>
                                <StepTitleWrapper>
                                    <NumberWrapper>
                                        <Txt>6</Txt>
                                    </NumberWrapper>
                                    <H3>
                                        <StepTitle>通販での購入もOK</StepTitle>
                                    </H3>
                                </StepTitleWrapper>
                                <FeatureImageWrapper>
                                    <StaticImage
                                        src="../images/rental/rental_step_06.jpg"
                                        alt=""
                                    />
                                </FeatureImageWrapper>
                            </RightWrapper>
                            <StepDescription size="s">
                                試着後にプラチナ製品をご購入の際はMissPlatinumホームページから通販でご注文頂けます。プラチナ製品をご注文されない場合も試着は無料でご利用頂けます。
                            </StepDescription>
                        </StepWrapper>
                    </section>
                    <section>
                        <RentalGuideWrapper>
                            <RentalGuideTitle>
                                <Txt>試着リング レンタルご利用ガイド</Txt>
                            </RentalGuideTitle>
                            <StaticImage
                                src="../images/rental/rental_03.jpg"
                                alt=""
                            />
                            <RentalGuideDesc>
                                <Txt size="s">
                                    ・試着用サンプルリングの注文方法はサンプル発注メールフォームからご希望の型番を送信
                                </Txt>
                                <SecondaryTxt size="s" theme={muiTheme}>
                                    ※携帯電話メールアドレスでご連絡の場合は弊社からの返信ドメイン「@missplatinum.net」を受信できる設定でご利用下さい。返信メールが届かない場合もあります。
                                </SecondaryTxt>
                            </RentalGuideDesc>
                            <RentalGuideDesc>
                                <Txt size="s">
                                    ・メール注文日から即日～３営業日で発送
                                </Txt>
                                <SecondaryTxt size="s" theme={muiTheme}>
                                    ※在庫がなくお待ち頂く場合はご連絡させて頂きます。
                                </SecondaryTxt>
                            </RentalGuideDesc>
                            <RentalGuideDesc>
                                <Txt size="s">
                                    ・レンタル期間は1週間、1回10ペアまで無料。
                                </Txt>
                                <SecondaryTxt size="s" theme={muiTheme}>
                                    ※ペアに限らず単品や単品の組み合わせでもご利用頂けます。
                                </SecondaryTxt>
                            </RentalGuideDesc>
                            <RentalGuideDesc>
                                <Txt size="s">
                                    ・試着用リングの材質はシルバー製の為、実際のプラチナ製品と比べ軽量ですが形状は同じです。
                                </Txt>
                            </RentalGuideDesc>
                            <RentalGuideDesc>
                                <Txt size="s">
                                    ・ご返却は到着後1週間以内に同封の返送封筒でポストに投函ください。
                                </Txt>
                            </RentalGuideDesc>
                            <RentalGuideDesc>
                                <Txt size="s">
                                    ・試着後、プラチナ製品購入の際はホームページ、通販ショッピングカートからご利用頂けます。
                                </Txt>
                                <SecondaryTxt size="s" theme={muiTheme}>
                                    ※購入に至らない場合も試着リングのレンタルは無料でご利用頂けますがお貸出しから1週間以上を経過し、一向にご返却頂けない場合はサンプルリングをご購入とさせて頂き商品代金を請求させて頂く場合がございます。
                                </SecondaryTxt>
                            </RentalGuideDesc>
                            <RentalButtonWrapper>
                                <RentalButton
                                    href="https://form1ssl.fc2.com/form/?id=4f52e4028c9a9ef1"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={goToRental}
                                >
                                    試着用リングを申し込む（無料）
                                </RentalButton>
                            </RentalButtonWrapper>
                            <Txt size="s">ご希望の商品型番を入力ください</Txt>
                        </RentalGuideWrapper>
                    </section>
                </Grid>
            </Grid>
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '8px 0 12px',
    display: 'flex',
    justifyContent: 'center',
})
const H2 = styled('h2')({
    margin: '32px 0 12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
const H3 = styled('h3')({
    margin: 0,
})
const Desc = styled('div')({
    padding: '10px 20px',
})
const Table = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '340px',
    margin: '12px auto 0',
    border: '1px solid #aaaaaa',
    borderBottom: 'none',
})
const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #aaaaaa',
})
const H = styled('div')({
    width: '120px',
    padding: '8px',
    borderRight: '2px solid #aaaaaa',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
})
const D = styled('div')({
    flex: 1,
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
})
const TableDesc = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '340px',
    margin: '4px auto 0',
})
const MapImageWrapper = styled('div')({
    maxWidth: '500px',
    margin: '8px auto 0',
})
const StepWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px 0',
})
const StepTitleWrapper = styled('section')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
})
const StepTitle = styled(Txt)({
    padding: '8px',
})
const NumberWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    backgroundColor: '#8b735e',
    color: '#ffffff',
    fontWeight: 'bold',
    marginLeft: '4px',
})
const FeatureImageWrapper = styled('div')({
    width: '90%',
})
const StepDescription = styled(Txt)({
    padding: '4px 8px',
})
const LeftWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '8px',
})
const RightWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '8px',
})
const RentalGuideWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '8px 0 20px',
})
const RentalGuideTitle = styled('h2')({
    marginBottom: '8px',
    padding: '8px',
    width: '100%',
    borderBottom: '1px solid #aaaaaa',
})
const RentalGuideDesc = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    width: '100%',
})
const RentalButtonWrapper = styled('div')({
    width: '300px',
    margin: '20px 0 4px',
})
const RentalButton = styled(Button)({
    padding: '8px 15px',
})
const SecondaryTxt = styled(Txt)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

export default RetalPage
